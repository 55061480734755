@import '@colors';
@import '@screens';


.AboutUs {
	display: flex;
	flex-direction: column;
	align-items: center;


	.AboutUs__Box {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 725px;

		@media(max-width: $Tablet_width_max) {
			max-width: 450px;
		}

		.AboutUs__Text {
			
		}
	}
}