@import '@colors';
@import '@screens';


.SideMenu {
	display: none;
	top: 55px;
	right: -375px;
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 375px;
	overflow-y: scroll;
	position: fixed;
	padding: 18px 27px 100px 32px;
	scrollbar-width: none;

	z-index: 10;
	background-color: #191A1F;
	box-sizing: border-box;
	transition: .2s right ease-in-out;

	&-Shown {
		right: 0;
		transition: .2s right ease-in-out;
	}
}

