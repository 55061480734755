@import '@colors';

.Item {
	display: flex;
	align-items: center;
	text-decoration: none;

	&:hover {
		.Item__Text {
			color: $White_1000;
			& + svg {
				stroke: $White_1000;
			}
		}
	}

	.Item__Text {
		color: $H1_950;
		transition: 0.2s ease-in-out;
		margin-right: 9px;

		& + svg {
			transition: 0.2s ease-in-out;
			stroke: $H1_950;
		}
	}

	&-active {
		.Item__Text {
			color: $White_1000;
			& + svg {
				transform: rotate(180deg);
				stroke: $White_1000;
			}
		}
	}
}