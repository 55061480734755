@import '@colors';
@import '@screens';

.Icon__Close {
	opacity: 0;
	height: 0;
	width: 0;
	transition: 0.2s ease-in-out;
	&-active {
		opacity: 1;
		height: auto;
		width: auto;
		transition: 0.2s ease-in-out;
	}
}

.Icon__Burger {
	opacity: 0;
	height: 0;
	width: 0;
	transition: 0.2s ease-in-out;
	&-active {
		opacity: 1;
		height: auto;
		width: auto;
		transition: 0.2s ease-in-out;
	}
}
