@import '@colors';
@import '@screens';


.ForWhom {
    .ForWhom__Title {
    }

    .ForWhom__Items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        justify-content: center;
        margin: 44px 0px 24px 0px;
        @media (max-width: $Tablet_width_max) {
            grid-gap: 12px;
			margin: 24px 0px 18px 0px;
        }
        @media (max-width: $Mobile_width_max) {
			grid-template-columns: repeat(1, 1fr);
        }

    }
}