@import '@colors';
@import '@screens';


.BlockWithTitle {
	background-color: $Black_900;
	padding: 31px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 18px;
	@media (max-width: $Tablet_width_max) {
		flex-direction: column;
		padding: 24px;
	}

	.BlockWithTitle__Title {
		text-align: center;
		margin-bottom: 24px;

	}
	.BlockWithTitle__Subtitle {
		margin-top: auto;		
	}

}