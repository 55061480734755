@import '@colors';
@import '@screens';

.DesktopMenu {
	display: flex;
	.DesktopMenu__Item {
		margin-right: 32px;
		
		@media (max-width: $Tablet_width_max) {
			display: none;
		}
		@media (max-width: 1080px) {
			margin-right: 20px;;
		}
	
	}
}