@import "~normalize.css";

body {
	margin: 0;
	font-family: 'BeelineSans-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #1B1D22;
	scrollbar-width: none;
}

html {
	scrollbar-width: none;
	scroll-behavior: smooth;
}

p {
	margin: 0;
}