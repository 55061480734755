@import '@colors';
@import '@screens';

.Content_Area {
    width: $ContentAreaWidth;
    margin-left: auto; 
    margin-right: auto; 
    left: 0;
    right: 0;
    @media(max-width: $ContentAreaWidth){
        width: 94%;
    }
}