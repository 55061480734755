@import '@colors';
@import '@screens';

.BlockTitle {
	margin: 58px 0px 0px 0px;
	color: $Text_850;
	@media (max-width: $Tablet_width_max) {
		margin: 48px 0px 0px 0px;
	}
	@media (max-width: $Mobile_width_max) {
		margin: 42px 0px 0px 0px;
	}
}