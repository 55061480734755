@import '@colors';
@import '@screens';


.MobileMenu {
	display: none;
	position: relative;

	@media (max-width: $Tablet_width_max) {
		display: flex;
	}

	.MobileMenu__Button {
		
	}

	.MobileMenu__SideMenu {

	}
}

