@import '@colors';
@import '@screens';



.UI_Typography {
    position: relative;
    font-family: 'Beeline Sans';
    font-style: normal;
    color: $White_1000;
    height: fit-content;
}
.UI_Typography_ProtectWrapper {
    position: relative;
}
.UI_Typography_Protect {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
}

@font-face {
	font-family: 'Beeline Sans';
	src: url('../../../build/fonts/BeelineSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/* C Type */
@mixin C1 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: .02em;
}
.UI_Typography_C1 {
    @include C1;
}

.UI_Typography_C2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.UI_Typography_C3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
}


/* Button Type */
.UI_Typography_Button1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: .03em;
}
.UI_Typography_Button2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: .03em;
}

/* Input Type */
.UI_Typography_Input1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
}
.UI_Typography_Input2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
}

/* O Type */
.UI_Typography_O1 {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
}
.UI_Typography_O2 {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    text-transform: uppercase;
}
.UI_Typography_O3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
}
.UI_Typography_O4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: .02em;
}

/* Heading Type */
.UI_Typography_H1 {
    font-weight: 700;
    font-size: 90px;
    line-height: 92px;
    letter-spacing: .04em;

    @media (max-width: $Tablet_width_max) {
        font-weight: 700;
        font-size: 48px;
        line-height: 52px;
        letter-spacing: .04em;
    }
}
.UI_Typography_H2 {
    font-weight: 700;
    font-size: 62px;
    line-height: 64px;
    letter-spacing: .04em;

    @media (max-width: $Tablet_width_max) {
        font-weight: 700;
        font-size: 32px;
        line-height: 37px;
    }
}

/* Subtitle Type */
.UI_Typography_S1 {
    font-weight: 900;
    font-size: 42px;
    line-height: 46px;
    letter-spacing: .02em;

    @media (max-width: $Tablet_width_max) {
        font-weight: 700;
        font-size: 30px;
        line-height: 37px;
    }
}
.UI_Typography_S2 {
    font-weight: 500;
    font-size: 42px;
    line-height: 42px;

    @media (max-width: $Tablet_width_max) {
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;
    }
}
.UI_Typography_S3 {
    font-weight: 700;
    font-size: 40px;
    line-height: 42px;
    letter-spacing: .02em;

    @media (max-width: $Tablet_width_max) {
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;
    }
}
.UI_Typography_S4 {
    font-weight: 500;
    font-size: 36px;
    line-height: 38px;

    @media (max-width: $Tablet_width_max) {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }
}
.UI_Typography_S5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;

    @media (max-width: $Tablet_width_max) {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }
}

/* Product Type */
.UI_Typography_P1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 38px;

    @media (max-width: $Tablet_width_max) {
        font-weight: 700;
        font-size: 22px;
        line-height: 24px;
    }
}
.UI_Typography_P2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: .02em;

    @media (max-width: $Tablet_width_max) {
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.02em;
    }
}
.UI_Typography_P3 {
	font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: .02em;
}
.UI_Typography_P4 {
	font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: .02em;
	@media (max-width: $Tablet_width_max) {
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.02em;
	}
}

/* Body Type */
@mixin B1 {
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    
    @media (max-width: $Tablet_width_max) {
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
    }
}
.UI_Typography_B1 {
    @include B1;
}

.UI_Typography_B2 {
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
        
    @media (max-width: $Tablet_width_max) {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
    }
}
.UI_Typography_B3 {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: .03em;
        
    @media (max-width: $Tablet_width_max) {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }
}

/* Tag Type */
.UI_Typography_Tag1 {
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    
    @media (max-width: $Tablet_width_max) {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
    }
}


/* OPTIONS Type */
.UI_Typography_WSPreline {
    white-space: pre-line;
}
.UI_Typography_ForBaner{
    display: flex;
    flex-direction: column;
    align-items: center;

    // @media (max-width: $Mobile_width_max) {
    //     align-items: flex-start;
    // }
}
.UI_Typography_Inline {
    display: inline;
}
.UI_Typography_TextAlignCenter {
    text-align: center;
}



.UI_Typography_MARCDOWN_NEWS {
    margin-top: 24px;
    white-space: pre-line;

    strong {
        @include B1;
        color: #FFFF;
    }

    p {
        @include C1;
        color: #B6B7B8;
    }

    em {
        @include C1;
        color: #B6B7B8;
        font-style: italic;
    }

    ol, ul {
        @include C1;
        color: #B6B7B8;
        list-style: disc;
        list-style-position: inside;
    }

    ol {
        list-style: decimal;
        list-style-position: inside;
    }

    a {
        @include C1;
        color: #5d8ebf;
    }

    blockquote {
        margin: 0px 0px 20px 20px;
        border-left: 5px solid #B6B7B8;
        padding: 10px 20px;
    }
}

@for $i from 1 to 99 {
    .UI_Typography_LineClamp-#{$i} {
        display: -webkit-box;
        -webkit-line-clamp: #{$i};
        -webkit-box-orient: vertical; 
        overflow: hidden;
    }
}
