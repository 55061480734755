@import '@colors';
@import '@screens';

.Footer {
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: #202228;
	padding-top: 42px;
	padding-bottom: 71px;
	padding-left: 50px;
	padding-right: 50px;

	.Footer__Title {
		color: $Text_1000;
		margin-bottom: 16px;
	}

	.Footer__Item {
		text-decoration: none;
	}
	.Footer__Address {
		max-width: 400px;
	}

	.Footer__Item:not(:last-child) {
		margin-bottom: 12px;
	}



	.Footer__Item-additional {
		&:not(:last-child) {
			margin-bottom: 4px;
		}
		&:first-of-type {
			margin-top: 14px;
		}
	}
}