@import '@colors';
@import '@screens';


.Links {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-bottom: 25px;
	border-bottom: 1px solid $Black_700;

	.Links__Item {
		margin-bottom: 28px;
		& > p {
			color: $Text_900;
		}
		justify-content: space-between;
	}
}