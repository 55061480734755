@import '@colors';
@import '@screens';

.Logo {
	
	.Logo__Image {
		width: 138px;
		height: 36px;

		@media (max-width: $Mobile_width_max) {
			width: 27px;
			height: 36px;

		}
	}
}

