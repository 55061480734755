/* Colors */
$Yellow_100: #FFFF53;
$Yellow_500: #FED305;
$Yellow_1000: #C6A200;

$Black_700: #434449;
$Black_800: #36383C;
$Black_900: #16171B;
$Black_1000: #000000;

$H1_1000: #686A6D;
$H1_950: #A4A5A7;
$H1_850: #D1D2D3;

$Text_1000: #848588;
$Text_950: #B6B7B8;
$Text_900: #E1E2E2;
$Text_850: #D1D2D3;

$Bg_900: #1E2025;
$Bg_800: #23252C;

$White_1000: #FFFFFF;

$Error: #FF5555;

$ColorsMap: (
    Yellow_100: $Yellow_100,
    Yellow_500: $Yellow_500,
    Yellow_1000: $Yellow_1000,

    Black_700: $Black_700,
    Black_800: $Black_800,
    Black_900: $Black_900,
    Black_1000: $Black_1000,

    H1_1000: $H1_1000,
    H1_950: $H1_950,
    H1_850: $H1_850,

    Text_1000: $Text_1000,
    Text_950: $Text_950,
	Text_900: $Text_900,
    Text_850: $Text_850,

    Bg_900: $Bg_900,
    Bg_800: $Bg_800,

    White_1000: $White_1000,

	Error: $Error,
);

.UI_Colors_ {

    @each $colorName, $color in $ColorsMap {
  
      &#{$colorName} {
        color: $color !important;
      }
    }
}