@import '@colors';
@import '@screens';


.BurgerButton {
	display: none;
	background-color: $Black_900;
	border: none;
	
	@media (max-width: $Tablet_width_max) {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 108px;
		height: 55px;
		cursor: pointer;
	}

	@media (max-width: $Mobile_width_max) {
		width: 59px;
		height: 55px;
	}

	.BurgerButton__Text {
		color: $Text_900;
		font-family: 'Beeline Sans';
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		
		@media (max-width: $Tablet_width_max) {
			margin-left: 10px;
		}
	}
}
