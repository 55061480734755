@import '@colors';
@import '@screens';

.Footer {
	padding-top: 36px;
	display: flex;
	flex-direction: column;
	
	.Footer__LabelAddress {
		margin-bottom: 8px;
	}
	
	.Footer__Address {
		margin-bottom: 29px;
	}

	.Footer__LabelConnect {
		margin-bottom: 8px;
	}

	.Footer__Phone {
		margin-bottom: 8px;
		text-decoration: none;
	}
	.Footer__Email {
		text-decoration: none;
	}
}