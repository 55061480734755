@import '@colors';
@import '@screens';

.Header {
	display: flex;
	align-items: center;
	height: 74px;
	background-color: #202228;
	padding-left: 77px;
	padding-right: 104px;
	box-sizing: border-box;

	@media (max-width: $Tablet_width_max) {
		position: fixed;
		top: 0;
		z-index: 10;
		width: 100%;
		height: 55px;
		padding-left: 29px;
		padding-right: 33px;
	}

	.Header_Menu {
		margin-left: auto;
	}

}