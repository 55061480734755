@import '@colors';
@import '@screens';

.Main {
	scrollbar-width: none;
	@media(max-width: $Tablet_width_max) {
		margin-top: 55px;
	}

	.Main__AboutUs {
		margin-top: 74px;
		margin-bottom: 120px;
		@media(max-width: $Tablet_width_max) {
			margin-top: 115px;
		}
	}
	.Main__ForWhom {
		margin-bottom: 80px;
	}
	.Main__Directions {
		margin-bottom: 80px;
	}
	.Main__Results {
		margin-bottom: 120px;
	}
}